<template>
  <div v-if="facebookId || googleId">
    <div class="divider">
      {{ $t('login.social.or') }}
    </div>

    <div class="columns is-gapless social-buttons">
      <div v-if="facebookId" class="column">
        <social-facebook :no-redirect="noRedirect" @success="x => $emit('success', x)" @loading="x => $emit('loading', x)" />
      </div>
      <div v-if="googleId" class="column">
        <social-google :no-redirect="noRedirect" @success="x => $emit('success', x)" @loading="x => $emit('loading', x)" />
      </div>
    </div>
  </div>
</template>

<script>
import SocialFacebook from './Social/Facebook'
import SocialGoogle from './Social/Google'

export default {
  components: { SocialFacebook, SocialGoogle },
  props: {
    noRedirect: {
      type: Boolean
    }
  },
  data () {
    return {
      facebookId: process.env.facebookId,
      googleId: process.env.googleId
    }
  }
}
</script>
