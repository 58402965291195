<script>
export default {
  methods: {
    successLogin (response, noRedirect) {
      this.$store.dispatch('auth/set', [response.data, true])

      this.$buefy.toast.open({
        message: this.$t('login.success'),
        type: 'is-success'
      })

      this.$api.cart.get()
        .then((res) => {
          this.$store.dispatch('page/updateCart', res)
        })
        .catch(() => {
          this.$store.dispatch('page/updateCart', null)
        })

      if (!noRedirect) {
        if (this.requiredPhoneToRegistration) {
          this.$router.push(this.localePath('auth-confirm-phone'))
        } else if (this.$store.state.auth.redirectPath) {
          this.$router.push({ path: this.$store.state.auth.redirectPath })
        } else {
          this.$router.push(this.localePath('index'))
        }
      }
    },
    errorLogin (response) {
      if (response && response.data && response.data.error === 'User account is disabled.') {
        this.$buefy.toast.open({
          message: this.$t('login.disabled'),
          type: 'is-danger'
        })
      } else {
        this.$buefy.toast.open({
          message: this.$t('login.error_login'),
          type: 'is-danger'
        })
      }
    }
  }
}
</script>
